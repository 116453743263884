<section class="contenedor">
    <div class="centro">
        <article>
            <img class="logo" src="../../assets/img/clientes/elemento_5.png">
            <h2>RENOVACIÓN</h2>
            <p>Si estas al día con el pago de tu crédito podés renovar el mismo monto en cualquier momento presentando sólo tu DNI</p>
        </article>
        <article>
            <img class="logo" src="../../assets/img/clientes/elemento_6.png">
            <h2>PAGO DE CUOTAS</h2>
            <!--
		<img class="elemento" src="../../assets/img/clientes/pagos_1.png">-->
            <div id="pagos">
                <img class="elemento" src="../../assets/img/clientes/pagos_01.png">
                <img class="elemento" src="../../assets/img/clientes/pagos_02.png">
                <img class="elemento" src="../../assets/img/clientes/pagos_03.png">
                <img class="elemento" src="../../assets/img/clientes/pagos_04.png">
                <img class="elemento" src="../../assets/img/clientes/pagos_05.png">
            </div>
        </article>
        <article>
            <img class="logo" src="../../assets/img/clientes/elemento_7.png">
            <h2>BENEFICIOS</h2>
            <p>Cancelación anticipada con quita total de intereses* <br> Crédito paralelo* <br> Adelanto de cuotas*</p>
        </article>
    </div>
</section>

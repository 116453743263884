import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ContactoService } from 'src/app/services/contacto.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})

export class ContactoComponent{

  btn_clase: string = "";
  btn_text: string = "Enviar";
  btn_loader: boolean = false;
  

  Contacto = new FormGroup({
    nombre: new FormControl('', [Validators.required]),
    dni: new FormControl('', [Validators.required]),
    telefono: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    comentario: new FormControl('', [Validators.required]),
    motivo: new FormControl('', [Validators.required]),
    region: new FormControl('', [Validators.required]),
  })

  constructor(
    private ContactoService: ContactoService
  ) { }
  
  onSubmit(){

    if(this.Contacto.valid){

      let datos: any = this.Contacto.value;

      this.btn_clase = "enviando";
      this.btn_text = "Enviando...";
      this.btn_loader = true;
      this.Contacto.disable();

      this.ContactoService.EnvForm(datos).subscribe(
        res => {
          
          this.btn_clase = "enviado";
          this.btn_text = "Su solictud fue enviada!";
          this.btn_loader = false;

          setTimeout(() => {

            this.btn_clase = "";
            this.btn_text = "Enviar";
            this.Contacto.enable();
            
          },4000);
        },
        err => {
          
          this.btn_clase = "fallo";
          this.btn_text = "Su solicitud NO fue enviada!";
          this.btn_loader = false;

          setTimeout(() => {
              this.btn_clase = "";
              this.btn_text = "Enviar";
              this.Contacto.enable();
          },5000);
        }
      );

    }  

  }


}


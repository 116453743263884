import { NgModule } from '@angular/core';
import { Routes, RouterModule,  ExtraOptions } from '@angular/router';
import { LegalesComponent } from './legales/legales.component';
import { PrincipalComponent } from './principal/principal.component';

import { SolicitarComponent } from './forms/solicitar/solicitar.component'
import { SimularComponent } from './forms/simular/simular.component';
import { ThankyouComponent } from './forms/thankyou/thankyou.component';



const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'disabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 0],
};

const routes: Routes = [
  { path: ':seccion', component: PrincipalComponent, children:[
    { path: '', component: SimularComponent},
    { path: 'solicitar', component: SolicitarComponent},
    { path: 'thankyou', component: ThankyouComponent}
  ]},
  { path: 'legales/:navbar', component: LegalesComponent},
  { path: '', component: PrincipalComponent, children:[
    { path: '', component: SimularComponent},
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

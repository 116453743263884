import { Component, Input, OnInit} from '@angular/core';
import { SimuladorService } from '../services/simulador.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit{

  @Input() seccion: string;

  menu='../../assets/img/menu.svg';
  close='../../assets/img/close.svg';
  logo='../../assets/img/logoColor_1.png';

  modal='modal';

  constructor(private SimuladorService: SimuladorService) {}



  ngOnInit(): void {

      this.translado('#' + this.seccion);


  }


  abrir()
    {
      this.modal='modal visible';
    }

  cerrar()
    {
      this.modal='modal';
    }

   translado(id){
    let x = document.querySelector(id);
    if (x)
	    {
          x.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
	        if (this.modal=='modal visible'){this.modal='modal';};
	    }
	}

}

<app-navbar [seccion]="seccion"></app-navbar>
<section [attr.id]='"principal"' id="principal">
    <app-slider></app-slider>
</section>
<section [attr.id]='"eleginos"' id="eleginos">
    <app-productos></app-productos>
</section>
<section [attr.id]='"solicitud"' id="solicitud">
    <app-solicitud></app-solicitud>
</section>
<section [attr.id]='"clientes"' id="clientes">
    <app-clientes></app-clientes>
</section>
<section [attr.id]='"contacto"' id="contacto">
    <app-contacto></app-contacto>
</section>

<app-navbar *ngIf="navbar"></app-navbar>
<section id="fondo">
    <input *ngIf="!navbar" type="button" value="Cerrar">
    <article>
        <section>
            <h2>Política de Privacidad</h2>
            <ol>
                <li>En el presente se describe la información que se recoge sobre los usuarios y el compromiso asumido por BUEN CAMINO S.R.L. respecto de su posterior utilización. Al registrarse, los usuarios aceptan que la relación entre las partes se regirá
                    por las pautas que se enuncian a continuación.</li>
                <li>La privacidad de la información de los usuarios es fundamental para BUEN CAMINO S.R.L. Por tal razón se toman las precauciones y recaudos necesarios para mantenerla resguardada y segura, utilizando los mecanismos de seguridad informática
                    más aptos disponibles en la actualidad.</li>
                <li>Este documento es parte integrante de los Términos y Condiciones Generales de www.creditosdelsur.com.ar. Mediante la aceptación de los Términos y Condiciones Generales en el momento de la registración el usuario acepta las políticas aquí
                    contenidas.
                </li>
                <li>
                    <h4>Información a recabar</h4>
                    <ol type="a">
                        <li>Para utilizar los servicios brindados por el sitio web, los usuarios deberán registrarse, completando el formulario respectivo que se pone a su disposición. BUEN CAMINO S.R.L. podrá confirmar dichos datos acudiendo a entidades
                            públicas, compañías especializadas o centrales de riesgo públicas o privadas. La información que BUEN CAMINO S.R.L. obtenga de estas entidades será tratada en forma confidencial y empleada exclusivamente para evaluar la solicitud
                            de crédito recibida.</li>
                        <li>Se establece que en cualquier momento, luego de concluida la relación comercial y no quedando saldo alguno a favor de BUEN CAMINO S.R.L., el usuario registrado en www.creditosdelsur.com.ar podrá solicitar la baja de su solicitud
                            y la eliminación de su cuenta e información de la base de datos de www.creditosdelsur.com.ar</li>
                        <li>BUEN CAMINO S.R.L. recoge y almacena automáticamente cierta información personal sobre la actividad de los usuarios dentro del sitio web. Tal información puede incluir la URL de la que provienen, a qué URL acceden seguidamente,
                            qué navegador están usando, y sus direcciones IP.</li>
                        <li>Si los usuarios envían correspondencia electrónica, BUEN CAMINO S.R.L. podrá recoger y almacenar tal información</li>
                        <li>BUEN CAMINO S.R.L. podrá informar del otorgamiento de cada préstamo a las bases de datos de las distintas centrales de riesgo crediticio públicas y privadas, y con posterioridad a ello, acerca del cumplimiento o eventual incumplimiento
                            del usuario respecto de las condiciones de pago convenidas.</li>
                    </ol>
                </li>
                <li>
                    <h4>Uso de la información</h4>
                    <ol type="a">
                        <li>
                            <p>Se emplea con el fin de suministrar el mejor servicio y posibilitar que los usuarios realicen operaciones en forma ágil y segura. La recolección de la información nos permite ofrecerles servicios y funcionalidades que se adecuan
                                mejor a sus necesidades y personalizar nuestros servicios para adecuarnos a sus necesidades y en especial para:</p>
                            <ol type="I">
                                <li>Establecer el contacto con los usuarios.</li>
                                <li>Desarrollar estudios internos sobre los intereses, el comportamiento y la demografía de los mismos con el objetivo de comprender mejor sus necesidades y darle un mejor servicio.</li>
                                <li>Mejorar nuestras iniciativas comerciales y promocionales, perfeccionar nuestra oferta de contenidos y personalizarlos.</li>
                                <li>Enviar información o mensajes sobre nuevos servicios, promociones, publicidad, banners, de interés para nuestros usuarios, además de la información expresamente autorizada en las preferencias. El Usuario puede siempre solicitar
                                    el retiro o bloqueo de su nombre de las bases de datos, como así también puede solicitar se lo excluya de las listas para el envío de información promocional o publicitaria.</li>
                                <li>El Titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no menores a 6 meses, salvo que se acredite un interés legítimo al respecto, conforme lo establecido
                                    en el art. 14, inciso 3 de la Ley Nro 25.326.La DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, Órgano de Control de la Ley Nro. 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan
                                    con relación al incumplimiento de las normas sobre protección de datos personales.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <h4>Confidencialidad de la información</h4>
                    <ol type="a">
                        <li>Una vez registrado en www.creditosdelsur.com.ar los datos de los usuarios serán utilizados únicamente en las formas establecidas en este documento. Haremos todo lo que esté a nuestro alcance para proteger la privacidad de la información.</li>
                        <li>Modos de acceso. Para interactuar dentro del sitio, los usuarios deben utilizar su dirección de correo electrónico, utilizada al momento de la registración, y la clave de acceso que los identificará.</li>
                    </ol>
                </li>
                <li>
                    <h4>Clave personal</h4>
                    Para acceder a los servicios reservados únicamente para los usuarios debidamente registrados, estos dispondrán de una clave personal que podrán modificar o sustituir en cualquier momento. Con ella podrán solicitar un crédito o notificar el ejercicio de
                    modalidades acordadas en el contrato respectivo, entre otras actividades. Los usuarios se comprometen a preservar dicha clave bajo absoluta confidencialidad y, a no revelarla o compartirla con otras personas bajo ningún concepto. Cada
                    usuario es exclusivo responsable por el uso de la clave personal en el sitio y asume las consecuencias derivadas de ello.
                </li>
                <li>
                    <h4>Capacidad</h4>Los servicios del sitio sólo están disponibles para aquellas personas que tengan capacidad legal para contratar. Por lo tanto, quienes no cumplan con esta condición deberán abstenerse de operar como usuarios del sitio.
                </li>
                <li>
                    <h4>Spam</h4>
                    <ol type="a">
                        <li>De acuerdo a su política de Protección de la Privacidad de sus usuarios, BUEN CAMINO S.R.L. toma los recaudos necesarios para evitar el envío no solicitado de e-mails.</li>
                        <li>BUEN CAMINO S.R.L. no se hace responsable por el envío de emails no solicitados con fines publicitarios por parte de terceros a usuarios y/o clientes del presente web site.</li>
                        <li>BUEN CAMINO S.R.L. podrá a su solo arbitrio suspender o inhabilitar definitivamente a aquellos usuarios que utilicen este servicio con cualquier otro fin distinto al previsto, renunciando expresamente los usuarios a todo reclamo
                            a consecuencias de dichas medidas.</li>
                    </ol>
                </li>
                <li>
                    <h4>Seguridad y almacenamiento de la información</h4>
                    <ol type="a">
                        <li>BUEN CAMINO S.R.L. se compromete a cumplir con la normativa en materia de medidas de seguridad aplicables a la información personal.Adicionalmente deja constancia de que emplea los estándares de la industria entre materia de protección
                            de la confidencialidad, incluyendo, entre otras medidas, cortafuegos ("firewalls")y Secure Socket Layers ("SSL").</li>
                        <li>BUEN CAMINO S.R.L. considera a los datos de sus usuarios como un activo que debe ser protegido de cualquier pérdida o acceso no autorizado. Emplea a tal fin técnicas de seguridad para proteger tales datos de accesos no autorizados
                            por terceros o incluso por otros usuarios del mismo sitio.</li>
                        <li>El usuario se compromete a que los datos ingresadas para la validación de su identidad como en su perfil son verdaderos, tiendo carácter de declaración jurada y sometiéndose a las normas y leyes vigentes sobre falsificación y datos
                            fraudulentos.
                        </li>
                        <li>El usuario se compromete a no divulgar sus credenciales se acceso como así también ningún dato obtenido mediante el ingreso autorizado al sistema. Los accesos no autorizados o la divulgación de información obtenida por parte del
                            mismo mediante cualquier medio serán penada pajo la ley de protección de datos personales (ley 25.326).</li>
                        <li>BUEN CAMINO S.R.L. no será responsable por interceptaciones ilegales o violación de sus sistemas o bases de datos por parte de personas no autorizadas provocadas por la divulgación de información por parte de los usuarios o clientes,
                            tampoco por la indebida utilización de la información obtenida por esos medios o el mismo usuario aplicando lo mencionado en el apartado c.</li>
                        <li>Todos los datos personales de los usuarios serán almacenados en una base de datos privada con fines de validación y acreditación de credenciales que certifiquen la veracidad del usuario que ingresa a nuestros sistemas.</li>
                        <li>El usuario al registrarse en nuestros sistemas acepta recibir boletines informativos mediante los medios registrados al momento de validar su identidad y declarados en su perfil de usuario como así también la utilización por parte
                            de BUEN CAMINO S.R.L. de los mismos como medio de comunicación.</li>
                        <li>BUEN CAMINO S.R.L. permitirá el acceso solo a información mínima y necesaria para que el usurario realice la operación requerida teniendo que comunicarse con la empresa a los teléfonos mencionados en nuestro sistema si fuera necesaria
                            la obtención de algún otro tipo de información que no se esté mostrando.</li>
                    </ol>
                </li>
                <li>
                    <h4>Derechos de acceso, cancelación y rectificación de la información personal</h4>Los usuarios tienen y podrán ejercitar en cualquier momento los derechos de acceder y actualizar su información personal, con la excepción de su nombre,
                    apellido y número de documento, así como a oponerse al tratamiento de la misma, todo ello de conformidad a lo dispuesto en la normativa aplicable. Los usuarios garantizan y responden en cualquier caso por la veracidad, exactitud, vigencia
                    y autenticidad de los datos personales facilitados, y se comprometen a mantenerlos debidamente actualizados.</li>
                <li>
                    <h4>Modificaciones de las Políticas de Privacidad</h4>BUEN CAMINO S.R.L. podrá modificaren cualquier momento los términos y condiciones de estas Políticas de Privacidad y confidencialidad y notificará los cambios al usuario por e-mail,
                    publicando una versión actualizada de las mismas en esta sección del sitio. Si el usuario no objetara las modificaciones dentro de los 2 (dos) días siguientes a la recepción del mensaje, se considerará que acepta los nuevos términos
                    y las partes quedarán sujetos a ellos a partir de esa fecha.</li>
                <li>Para mayor información sobre la confidencialidad de sus datos personales, contáctenos por correo electrónico a: legales@creditosdelsur.com.ar</li>
                <li>El presente convenio se encuentra a disposición de los usuarios que deseen contar con el texto impreso en la sede de BUEN CAMINO S.R.L., Misiones N° 1768 de la ciudad de General Roca, Rio Negro.</li>
            </ol>
        </section>
        <section>
            <h2>Términos y Condiciones</h2>
            <h3>INTRODUCCIÓN. PRINCIPIOS BÁSICOS.</h3>
            <p> El acceso a este Sitio y la utilización de sus servicios está sujeto a los Términos y Condiciones que se establecen a continuación (los "Términos y Condiciones"). Usted debe leer, entender y aceptar todas las políticas establecidas en los
                Términos y Condiciones como condición para al Sitio y utilizar los servicios que ofrece. Cualquier persona que desee acceder al Sitio y/o utilizar sus servicios ("el Usuario") podrá hacerlo sujetándose a estos Términos y Condiciones, considerándose
                que el Usuario ha aceptado los Términos y Condiciones en el caso de que el Usuario realice cualquier operación en el Sitio.</p>
            <p> En el caso que el Usuario no acepte estos Términos y Condiciones, deberá abstenerse de ingresar al Sitio y de utilizar los servicios que en él se ofrecen.</p>
            <p> El acceso al Sitio sólo está disponible para las personas mayores de dieciocho (18) años, con capacidad legal para contratar, que cumplan los recaudos requeridos por BUEN CAMINO S.R.L. Una vez aceptados los Términos y Condiciones, las relaciones
                entre el Usuario y BUEN CAMINO S.R.L. ("el Titular") derivadas de la utilización del Sitio y/o de los servicios ofrecidos mediante éste, se regirán por las siguientes cláusulas:</p>
            <ol>
                <li>
                    <h4>BUENA FE</h4>
                    El Usuario reconoce y acepta que estos Términos y Condiciones y las relaciones surgidas a través del mismo, tendrán como principios esenciales la buena fe y el deber de colaboración entre las partes. En consecuencia, el Usuario se obliga a interactuar
                    en el Sitio sobre la base de esos principios.</li>
                <li>
                    <h4>MODIFICACIONES A LOS TÉRMINOS Y CONDICIONES</h4>El Titular se reserva el derecho de modificar estos Términos y Condiciones. Dichas modificaciones entrarán en vigencia a partir del momento en que sean publicadas en este Sitio o notificadas
                    al Usuario, lo que ocurra primero. La información y los contenidos de este Sitio pueden contener errores tipográficos o inexactitudes. El Titular no asume obligación o responsabilidad alguna de poner al día o de enmendar cualquier
                    información. El Titular puede continuar, discontinuar o cambiar cualquier producto o servicio ofrecido en este Sitio en cualquier momento. Asimismo, el Titular se reserva el derecho, de cambiar, mejorar o corregir la información, los
                    contenidos y las descripciones y explicaciones en este Sitio, y suspender y/o negar el acceso a este Sitio para su mantenimiento (programado o no programado). En caso que la operación esté concertada y las modificaciones incidan relevantemente
                    en las condiciones del contrato, las mismas serán informadas al cliente por e-mail y se realizaran de acuerdo a las disposiciones que le sean aplicables. En caso que el Usuario no acepte las modificaciones, deberá comunicar fehacientemente
                    al Titular dentro de los cinco días de haber sido notificado y podrá dar por rescindido el contrato. El Usuario tendrá un máximo de quince días para pre cancelar el saldo adeudado, a partir del momento en que se comunica la rescisión.</li>
                <li>
                    <h4>PROPIEDAD DE DERECHOS, TÍTULOS Y CONTENIDOS</h4>Todos los derechos, títulos y contenidos de este Sitio son exclusiva propiedad del Titular salvo que se indique lo contrario y por lo tanto, los mismos no se pueden modificar, copiar,
                    distribuir, transmitir, reproducir, publicar, licenciar, fotografiar, transferir o recibir cualquier otro uso en forma comercial o para propósitos públicos en todo o en parte, sin previa autorización por escrito del Titular.</li>
                <li>
                    <h4>REQUISITOS</h4>Para acceder a cualquiera de los servicios ofrecidos mediante el Sitio por el Titular, el Usuario deberá cumplimentar todos los requisitos descriptos en El Sitio para poder contratar dichos servicios. La aceptación de
                    los presentes Términos y Condiciones, implicará que el Usuario presta su total y pleno acuerdo con todos los términos y condiciones establecidos, tanto para el sitio como para cada servicio en particular.</li>
                <li>
                    <h4>FORMULARIO DE SOLICITUD DE CRÉDITO – REGISTRACIÓN DEL USUARIO</h4>
                    <p>Una vez aceptados los presentes Términos y Condiciones, el Usuario se compromete a cumplir con las pautas que se describen a continuación.</p>
                    <p>El Usuario deberá registrarse con sus datos personales completando todos los campos marcados como obligatorios en el formulario de Solicitud de Crédito y proporcionando información válida y veraz. El Usuario garantiza la veracidad,
                        exactitud y vigencia de los datos ingresados con motivo de su Solicitud de Crédito y se obliga a informar al Titular lo antes posible cualquier cambio en la información provista. El Titular o el acreedor (en caso de que éste sea
                        distinto al Titular) podrán solicitar al Usuario la presentación o envío de información complementaria (comprobantes, declaraciones juradas impositivas, etc.) que se consideren necesarios para verificar los datos declarados por
                        el Usuario y/o efectuar el debido análisis crediticio a los efectos de otorgar el crédito solicitado al Usuario. El Titular se reserva el derecho de suspender o impedir el acceso al Usuario cuyos datos no hayan sido debidamente
                        corroborados sin que ello genere derecho alguno al Usuario. El Usuario autoriza al Titular a recabar todos los datos públicos o privados, entre otros los proveídos por empresas de bases de datos especializadas- que considere necesarios
                        para confirmar los suministrados por el Usuario.</p>
                    <p>El Usuario accederá a su Cuenta personal (la "Cuenta") mediante el ingreso de su dirección de correo electrónico y una clave de acceso personal (la "Clave"). La Clave podrá ser modificada en cualquier momento por el Usuario. El Usuario
                        se obliga a no comunicar a terceros la clave y asume responsabilidad exclusiva y excluyente por las consecuencias derivadas de su uso. Sin perjuicio de la responsabilidad que asume el Usuario, se obliga a notificar al Titular,
                        y en su caso, también al acreedor si éste fuera otra entidad en forma inmediata y por medio fehaciente de cualquier uso o posibilidad de uso no autorizado de su Clave y/o del ingreso al Sitio y/o a su Cuenta por terceros no autorizados
                        por él.</p>
                    <p>El Titular y, en su caso, el acreedor si éste fuera otra entidad, se reservan el derecho de rechazar cualquier solicitud de registración así como el de cancelar una registración previamente aceptada, sin obligación de comunicar o exponer
                        las razones de su decisión y sin que ello genere derecho alguno a favor del Usuario.</p>
                </li>
                <li>
                    <h4>COMPROMISO DEL USUARIO</h4>
                    <p>El Usuario se compromete a no:</p>
                    <ol type="a">
                        <li>Transmitir mensajes anónimos o bajo un seudónimo.</li>
                        <li>Transmitir mensajes o información injuriosa, intimidatoria, engañosa, difamatoria, obscena, o que viole el derecho a la intimidad o la propiedad intelectual o industrial de terceros, o que pueda resultar perjudicial para éstos
                            o para el Titular.</li>
                        <li>Transmitir ningún archivo que contenga virus o cualquier otro elemento contaminante o destructivo para el Sitio como pueden ser un robot, araña (spider), gusano, troyano, u otros aparatos automáticos o procesos manuales para monitorear
                            o copiar las páginas del Sitio o la información contenida en él.</li>
                        <li>Transmitir publicidad, materiales promocionales, u ofertas excepto en aquellas áreas (Ej. Cartelera de Avisos clasificados, Carteleras de Mensajes) destinadas a ese fin.</li>
                        <li>Enviar e-mails no solicitados, en forma masiva, a otros Usuarios.</li>
                        <li>Intentar tener acceso no autorizado, utilizar el Sitio en alguna manera que pudiera dañar, inhabilitar, sobrecargar o deteriorar su contenido o funcionalidad o efectuar cualquier otro acto reñido con la finalidad del Sitio o con
                            sus Términos y Condiciones.</li>
                    </ol>
                </li>
                <li>
                    <h4>RESPONSABILIDAD DEL TITULAR</h4>
                    <p>El Titular no se responsabiliza por:</p>
                    <ol type="a">
                        <li>La publicidad de terceros que contenga el Sitio ni será responsable por las vinculaciones que el Usuario establezca con ellos.</li>
                        <li>Las eventuales consecuencias de cualquier índole resultantes de fallas en el sistema, en el servidor o en Internet que pudieran surgir del acceso y/o uso del Sitio bajo cualquier modalidad que el Usuario empleara.</li>
                        <li>El Titular no garantiza el acceso y uso continuado o ininterrumpido de su Sitio. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de Internet. En tales casos se procurará restablecerlo
                            con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad. El Titular no será responsable por eventuales errores u omisiones contenidos en el Sitio.</li>
                    </ol>
                </li>
                <li>
                    <h4>OTORGAMIENTO DE CRÉDITO – SERVICIO</h4>
                    <p>El servicio ofrecido por el Titular consiste en la gestión online de créditos personales al Usuario de acuerdo a los montos, plazos y condiciones ofrecidos por El Titular, o de terceros cuyos productos decidiera ofrecer el Titular.
                        Se expondrá permanentemente en el Sitio: los montos que el Usuario podrá solicitar, cantidad de cuotas, forma de pago y condiciones contractuales. En caso de concretarse una posible operación:</p>
                    <ol type="a">
                        <li>El Sitio le informará los montos y fechas de vencimientos de cada una de las cuotas.</li>
                        <li>El Usuario podrá disponer del texto de la solicitud de crédito y su correspondiente pagaré mediante el cual se instrumentará su compromiso de pago teniendo la posibilidad de guardarlo en su computadora o bien imprimirlo.</li>
                        <li>Concretados los puntos anteriores el Usuario podrá realizar la solicitud de crédito en el Sitio, a la cual se le dará una respuesta dentro de las 24 hs. hábiles posteriores al envío de la solicitud, mediante e-mail , a la dirección
                            de correo electrónico informada por el Usuario.</li>
                        <li>El Titular posee la exclusiva y libre potestad de la aprobación o no del crédito solicitado, no asumiendo ningún tipo de obligación de otorgar el mismo. El Usuario acepta expresamente que, en ningún caso, podrá alegar perjuicio
                            alguno por el no otorgamiento.</li>
                        <li>Como consecuencia de la solicitud de crédito, el Usuario autoriza al Titular a solicitar información de bases de datos complementaria y/o ratificatoria de la proporcionada por él a los fines de la evaluación crediticia que el Titular
                            efectuará a su entero y único arbitrio.</li>
                    </ol>
                </li>
                <li>
                    <h4>PROPIEDAD INTELECTUAL. ENLACES.</h4>
                    <ol type="a">
                        <li>Los contenidos de las pantallas relativas a los servicios del sitio como así también los programas, bases de datos, redes, archivos que permiten al Usuario acceder y usar su Cuenta, son de propiedad del Titular y están protegidas
                            por las leyes y los tratados internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización
                            expresa y por escrito del Titular.</li>
                        <li>El Sitio puede contener enlaces a otros sitios web, lo cual no indica que sean propiedad u operados por el Titular.</li>
                        <li>En virtud de que el Titular no tiene control sobre tales sitios, NO será responsable por los contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de los
                            mismos, sean causadas directa o indirectamente.</li>
                        <li>La presencia de enlaces a otros sitios web no implica una sociedad, relación, aprobación, aval, garantía o respaldo del Titular a dichos sitios y sus contenidos.</li>
                        <li>El sitio recomienda encarecidamente al usuario la previa lectura de los términos de uso y políticas de cualquier sitio enlazado.</li>
                    </ol>
                </li>
                <li>
                    <h4>RENUNCIAS</h4>
                    <p>La demora u omisión del Titular en exigir el estricto cumplimiento de estos Términos y Condiciones no podrá en ningún caso interpretarse como renuncia a sus derechos.</p>
                </li>
                <li>
                    <h4>NOTIFICACIONES y PUBLICIDAD</h4>
                    <p>El Usuario acepta la plena validez de las notificaciones enviadas por el Titular o en su caso, también del acreedor si éste fuera otra entidad, a través del Sitio y/o por correo electrónico, o bien por nota enviada por correo al domicilio
                        indicado en oportunidad de completar los datos de la Solicitud de Crédito. Así mismo el Usuario acepta la plena validez de las notificaciones enviadas por éste, al Titular del servicio, a través de correo electrónico o bien por
                        correo con franqueo pagado o courier, destacándose que el presente contrato se celebra sobre la base de la buena fe contractual y el deber de colaboración de las partes. El Usuario acepta el envío de material promocional y publicitario
                        enviados por el Titular, a través del sitio, correo electrónico, telefóno móvil, o bien por nota enviada por correo al domicilio indicado en oportunidad de completar los datos de la Solicitud de Crédito. El Usuario podrá solicitar
                        el no envío de material promocional y publicitario en cualesquiera de las formas mencionadas precedentemente</p>
                </li>
                <li>
                    <h4>JURISDICCIÓN – LEY APLICABLE</h4>
                    <p>Para todos los efectos de la presente, las partes se someten a la competencia de la Justicia Provincial de la Provincia de Rio Negro. El Titular constituye domicilio especial en Misiones N° 1768 de la ciudad de General Roca, Rio Negro</p>
                </li>
                <li>
                    <h4>OTRAS DISPOSICIONES</h4>
                    <ol type="a">
                        <li>El Usuario antes de aceptar los Términos y Condiciones generales ha tomado pleno conocimiento de los mismos, los ha tenido a la vista y leído con detenimiento y cuenta con copia de ellos a su disposición, pudiendo guardarla en
                            su computadora, imprimirla o retirar copia firmada por el Titular en el domicilio especial fijado por la misma.</li>
                        <li>El Usuario presta desde ya su conformidad para que el acreedor pueda ceder libremente los derechos resultantes de cualquier préstamo que solicitase, sin que ello pueda implicar modificación alguna de las obligaciones asumidas por
                            el Usuario. En caso que la cesión se realizara conforme a lo dispuesto en los arts. 70,71 y 72 de la ley 24.441, no será requisito la notificación al Usuario, deudor del préstamo.</li>

                    </ol>
                </li>
            </ol>
        </section>
    </article>
    <!--
<section class="footer">
	<p>Préstamos en efectivo para el consumo de $1.000 a $300.000, a devolverse en cuotas fijas en pesos argentinos,
	iguales, mensuales y consecutivas. Tipo de amortización del capital: Francés. En todos los casos,
	la Tasa Efectiva Anual (T.E.A.) y el Costo Financiero Total (C.F.T.) varían según el monto y el plazo
	de financiación elegido. En todos los casos serán informados claramente al momento de ingresar la solicitud
	del préstamo y antes de su otorgamiento. El periodo mínimo para la devolución de un préstamo es de 3 meses
	(a excepción de la modalidad “adelanto” la cual se devuelve en una sola vez), y el máximo 24 meses. La T.E.A.
	mínima es de 131,54% y la máxima 165,29%.<br>Ejemplo de préstamo: Monto solicitado: $50.000 a 12 meses. T.E.A sin IVA: 164,38%, C.F.T. sin IVA: 111,67%, CFTc/IVA: 135,12%Ç, valor cuota pura: $8.819,39, Cuota promedio c/IVA: 9.796,46. Total a pagar con IVA: $117.557,52.<br>El otorgamiento del préstamo está sujeto a evaluación crediticia. Los préstamos son comercializados por BUEN CAMINO S.R.L., CUIT N° 30-71542348-7, con domicilio en Misiones N° 1768, General Roca, Río Negro, C.P. 8332, Teléfono de contacto (0298)-4424447
	</p>
	<mat-checkbox color="primary"><a>Acepto términos y condiciones</a></mat-checkbox>
</section>
-->
</section>


<!--


-->

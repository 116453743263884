<form class="form" [formGroup]="Simular">
    <mat-form-field>
        <mat-label>PROVINCIA</mat-label>
        <mat-select formControlName="provincia" matNativeControl>
            <mat-option value="none"></mat-option>
            <mat-option *ngFor="let item of provincias" [value]="item.id">{{ item.prov }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>LOCALIDAD</mat-label>
        <mat-select formControlName="localidad" matNativeControl>
            <mat-option value="none"></mat-option>
            <mat-option *ngFor="let item of localidadesxprovincias" [value]="item.id">{{ item.loc }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>NOMBRE Y APELLIDO</mat-label>
        <mat-label *ngIf="Simular.get('nombre').hasError('minlength')"> mínimo 5 caracteres</mat-label>
        <input formControlName="nombre" type="text" matInput>
    </mat-form-field>
    <mat-form-field>
        <mat-label>DNI</mat-label>
        <mat-label *ngIf="Simular.get('dni').hasError('minlength') || Simular.get('dni').hasError('maxLength')"> mínimo 7 y máximo 8 números</mat-label>
        <input mascaraSoloNumeroInput mascaraNumeroInput formControlName="dni" type="text" matInput>
    </mat-form-field>
    <div class="telefono">
        <div>0</div>
        <mat-form-field class="codigo-area">
            <mat-label *ngIf="!Simular.get('area').hasError('minlength') && !Simular.get('area').hasError('maxLength')">C. AREA</mat-label>
            <mat-label *ngIf="Simular.get('area').hasError('minlength') || Simular.get('area').hasError('maxLength')"> Mín 2 Máx 5</mat-label>
            <input mascaraSoloNumeroInput formControlName="area" type="text" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label *ngIf="!Simular.get('telefono').hasError('minlength') && !Simular.get('telefono').hasError('maxLength')">NUMERO DE TELÉFONO SIN 15</mat-label>
            <mat-label *ngIf="Simular.get('telefono').hasError('minlength') || Simular.get('telefono').hasError('maxLength')"> Mínimo 6 y máximo 7 números</mat-label>
            <input mascaraSoloNumeroInput formControlName="telefono" type="text" matInput>
        </mat-form-field>
    </div>
    <mat-form-field class="sexo">
        <mat-label>SEXO</mat-label>
        <mat-select formControlName="sexo" matNativeControl>
            <mat-option value="none"></mat-option>
            <mat-option value="MASCULINO">MASCULINO</mat-option>
            <mat-option value="FEMENINO">FEMENINO</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>IMPORTE A SOLICITAR</mat-label>
        <mat-select class="scroll" formControlName="importe" matNativeControl>
            <mat-option value="none"></mat-option>
            <mat-option value="50000"> $50.000,00 </mat-option>
            <mat-option value="51000"> $51.000,00 </mat-option>
            <mat-option value="52000"> $52.000,00 </mat-option>
            <mat-option value="53000"> $53.000,00 </mat-option>
            <mat-option value="54000"> $54.000,00 </mat-option>
            <mat-option value="55000"> $55.000,00 </mat-option>
            <mat-option value="56000"> $56.000,00 </mat-option>
            <mat-option value="57000"> $57.000,00 </mat-option>
            <mat-option value="58000"> $58.000,00 </mat-option>
            <mat-option value="59000"> $59.000,00 </mat-option>
            <mat-option value="60000"> $60.000,00 </mat-option>
            <mat-option value="61000"> $61.000,00 </mat-option>
            <mat-option value="62000"> $62.000,00 </mat-option>
            <mat-option value="63000"> $63.000,00 </mat-option>
            <mat-option value="64000"> $64.000,00 </mat-option>
            <mat-option value="65000"> $65.000,00 </mat-option>
            <mat-option value="66000"> $66.000,00 </mat-option>
            <mat-option value="67000"> $67.000,00 </mat-option>
            <mat-option value="68000"> $68.000,00 </mat-option>
            <mat-option value="69000"> $69.000,00 </mat-option>
            <mat-option value="70000"> $70.000,00 </mat-option>
            <mat-option value="71000"> $71.000,00 </mat-option>
            <mat-option value="72000"> $72.000,00 </mat-option>
            <mat-option value="73000"> $73.000,00 </mat-option>
            <mat-option value="74000"> $74.000,00 </mat-option>
            <mat-option value="75000"> $75.000,00 </mat-option>
            <mat-option value="76000"> $76.000,00 </mat-option>
            <mat-option value="77000"> $77.000,00 </mat-option>
            <mat-option value="78000"> $78.000,00 </mat-option>
            <mat-option value="79000"> $79.000,00 </mat-option>
            <mat-option value="80000"> $80.000,00 </mat-option>
            <mat-option value="81000"> $81.000,00 </mat-option>
            <mat-option value="82000"> $82.000,00 </mat-option>
            <mat-option value="83000"> $83.000,00 </mat-option>
            <mat-option value="84000"> $84.000,00 </mat-option>
            <mat-option value="85000"> $85.000,00 </mat-option>
            <mat-option value="86000"> $86.000,00 </mat-option>
            <mat-option value="87000"> $87.000,00 </mat-option>
            <mat-option value="88000"> $88.000,00 </mat-option>
            <mat-option value="89000"> $89.000,00 </mat-option>
            <mat-option value="90000"> $90.000,00 </mat-option>
            <mat-option value="91000"> $91.000,00 </mat-option>
            <mat-option value="92000"> $92.000,00 </mat-option>
            <mat-option value="93000"> $93.000,00 </mat-option>
            <mat-option value="94000"> $94.000,00 </mat-option>
            <mat-option value="95000"> $95.000,00 </mat-option>
            <mat-option value="96000"> $96.000,00 </mat-option>
            <mat-option value="97000"> $97.000,00 </mat-option>
            <mat-option value="98000"> $98.000,00 </mat-option>
            <mat-option value="99000"> $99.000,00 </mat-option>
            <mat-option value="100000"> $100.000,00 </mat-option>
            <mat-option value="105000"> $105.000,00 </mat-option>
            <mat-option value="110000"> $110.000,00 </mat-option>
            <mat-option value="115000"> $115.000,00 </mat-option>
            <mat-option value="120000"> $120.000,00 </mat-option>
            <mat-option value="125000"> $125.000,00 </mat-option>
            <mat-option value="130000"> $130.000,00 </mat-option>
            <mat-option value="135000"> $135.000,00 </mat-option>
            <mat-option value="140000"> $140.000,00 </mat-option>
            <mat-option value="145000"> $145.000,00 </mat-option>
            <mat-option value="150000"> $150.000,00 </mat-option>
            <mat-option value="155000"> $155.000,00 </mat-option>
            <mat-option value="160000"> $160.000,00 </mat-option>
            <mat-option value="165000"> $165.000,00 </mat-option>
            <mat-option value="170000"> $170.000,00 </mat-option>
            <mat-option value="175000"> $175.000,00 </mat-option>
            <mat-option value="180000"> $180.000,00 </mat-option>
            <mat-option value="185000"> $185.000,00 </mat-option>
            <mat-option value="190000"> $190.000,00 </mat-option>
            <mat-option value="195000"> $195.000,00 </mat-option>
            <mat-option value="200000"> $200.000,00 </mat-option>
            <mat-option value="205000"> $205.000,00 </mat-option>
            <mat-option value="210000"> $210.000,00 </mat-option>
            <mat-option value="215000"> $215.000,00 </mat-option>
            <mat-option value="220000"> $220.000,00 </mat-option>
            <mat-option value="225000"> $225.000,00 </mat-option>
            <mat-option value="230000"> $230.000,00 </mat-option>
            <mat-option value="235000"> $235.000,00 </mat-option>
            <mat-option value="240000"> $240.000,00 </mat-option>
            <mat-option value="245000"> $245.000,00 </mat-option>
            <mat-option value="250000"> $250.000,00 </mat-option>
            <mat-option value="255000"> $255.000,00 </mat-option>
            <mat-option value="260000"> $260.000,00 </mat-option>
            <mat-option value="265000"> $265.000,00 </mat-option>
            <mat-option value="270000"> $270.000,00 </mat-option>
            <mat-option value="275000"> $275.000,00 </mat-option>
            <mat-option value="280000"> $280.000,00 </mat-option>
            <mat-option value="285000"> $285.000,00 </mat-option>
            <mat-option value="290000"> $290.000,00 </mat-option>
            <mat-option value="295000"> $295.000,00 </mat-option>
            <mat-option value="300000"> $300.000,00 </mat-option>
            <mat-option value="305000">$305.000,00</mat-option>
            <mat-option value="310000">$310.000,00</mat-option>
            <mat-option value="315000">$315.000,00</mat-option>
            <mat-option value="320000">$320.000,00</mat-option>
            <mat-option value="325000">$325.000,00</mat-option>
            <mat-option value="330000">$330.000,00</mat-option>
            <mat-option value="335000">$335.000,00</mat-option>
            <mat-option value="340000">$340.000,00</mat-option>
            <mat-option value="345000">$345.000,00</mat-option>
            <mat-option value="350000">$350.000,00</mat-option>
            <mat-option value="355000">$355.000,00</mat-option>
            <mat-option value="360000">$360.000,00</mat-option>
            <mat-option value="365000">$365.000,00</mat-option>
            <mat-option value="370000">$370.000,00</mat-option>
            <mat-option value="375000">$375.000,00</mat-option>
            <mat-option value="380000">$380.000,00</mat-option>
            <mat-option value="385000">$385.000,00</mat-option>
            <mat-option value="390000">$390.000,00</mat-option>
            <mat-option value="395000">$395.000,00</mat-option>
            <mat-option value="400000">$400.000,00</mat-option>
            <mat-option value="405000">$405.000,00</mat-option>
            <mat-option value="410000">$410.000,00</mat-option>
            <mat-option value="415000">$415.000,00</mat-option>
            <mat-option value="420000">$420.000,00</mat-option>
            <mat-option value="425000">$425.000,00</mat-option>
            <mat-option value="430000">$430.000,00</mat-option>
            <mat-option value="435000">$435.000,00</mat-option>
            <mat-option value="440000">$440.000,00</mat-option>
            <mat-option value="445000">$445.000,00</mat-option>
            <mat-option value="450000">$450.000,00</mat-option>
            <mat-option value="455000">$455.000,00</mat-option>
            <mat-option value="460000">$460.000,00</mat-option>
            <mat-option value="465000">$465.000,00</mat-option>
            <mat-option value="470000">$470.000,00</mat-option>
            <mat-option value="475000">$475.000,00</mat-option>
            <mat-option value="480000">$480.000,00</mat-option>
            <mat-option value="485000">$485.000,00</mat-option>
            <mat-option value="490000">$490.000,00</mat-option>
            <mat-option value="495000">$495.000,00</mat-option>
            <mat-option value="500000">$500.000,00</mat-option>
            <mat-option value="550000">$550.000,00</mat-option>
            <mat-option value="600000">$600.000,00</mat-option>
            <mat-option value="650000">$650.000,00</mat-option>
            <mat-option value="700000">$700.000,00</mat-option>
            <mat-option value="750000">$750.000,00</mat-option>
            <mat-option value="800000">$800.000,00</mat-option>
            <mat-option value="850000">$850.000,00</mat-option>
            <mat-option value="900000">$900.000,00</mat-option>
            <mat-option value="950000">$950.000,00</mat-option>
            <mat-option value="1000000">$1.000.000,00</mat-option>
            <mat-option value="1500000">$1.500.000,00</mat-option>
            <mat-option value="2000000">$2.000.000,00</mat-option>
            <mat-option value="2500000">$2.500.000,00</mat-option>
            <mat-option value="3000000">$3.000.000,00</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>CANTIDAD DE CUOTAS</mat-label>
        <mat-select formControlName="cuotas" matNativeControl>
            <mat-option value="none"></mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="6">6</mat-option>
            <mat-option value="12">12</mat-option>
            <mat-option value="15">15</mat-option>
            <mat-option value="18">18</mat-option>
            <mat-option value="24">24</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="terminos">
        <mat-checkbox (click)="check_legales($event)" color="primary"><a [routerLink]="['/legales/true']" target="_blank">Acepto términos y condiciones</a></mat-checkbox>
    </div>
    <div class="control-btn prin-btn">
        <input [disabled]="!legal_activo" type="submit" (click)="btn_simular($event)" value="Simular">
    </div>
</form>

<section class="contenedor">
    <section class="slider">
        <!--
        <div class="boton izq" (click)="anterior()">
          <span id="atras" class="material-icons">navigate_before</span>
        </div>
        -->
        <ng-container *ngFor="let slide of slides; let i = index">
            <img *ngIf="i === actual" [src]="slide.src" @slideAnimacion>
        </ng-container>
        <ng-container *ngFor="let slide of slides; let i = index">
            <span *ngIf="i === actual" class="enlace-imagen" (click)="translado(slide.href)">
				<a [href]="slide.href">Más info</a>
			</span>
        </ng-container>
        <div class="posicion">
            <ng-container *ngFor="let slide of slides; let i = index">
                <span [class]="slide.clase" (click)="Seleccionar(i)"></span>
            </ng-container>
        </div>
        <!--
        <div class="boton der" (click)="siguiente()">
            <span id="adelante" class="material-icons">navigate_next</span>
        </div>
        -->
    </section>
</section>
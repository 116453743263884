<section class="contenedor">
    <form [formGroup]="Contacto" (ngSubmit)="onSubmit()">
        <mat-form-field>
            <mat-label>NOMBRE Y APELLIDO</mat-label>
            <input formControlName="nombre" type="text" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>DNI</mat-label>
            <input formControlName="dni" type="number" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>TELEFONO</mat-label>
            <input formControlName="telefono" type="number" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>EMAIL</mat-label>
            <input formControlName="email" type="email" matInput>
        </mat-form-field>

        <mat-form-field>
            <mat-label>MOTIVOS</mat-label>
            <mat-select class="achicamotivo" formControlName="motivo" matNativeControl required>
                <mat-option value="none"></mat-option>
                <mat-option value="Solicitar Información">- Solicitar Información</mat-option>
                <mat-option value="Informar Pago">- Informar Pago</mat-option>
                <mat-option value="Otros">- Otros</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>SUCURSAL</mat-label>
            <mat-select formControlName="region" matNativeControl required>
                <mat-option value="none"></mat-option>
                <mat-option value="valle">- General Roca</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>COMENTARIO</mat-label>
            <textarea formControlName="comentario" matInput></textarea>
        </mat-form-field>
        <div class="captcha">
            <re-captcha siteKey="6LfzeN0SAAAAAEBZT1kNHKGShSAM_vemLK7285bm"></re-captcha>
        </div>
        <div class="terminos">
            <mat-checkbox color="primary"><a [routerLink]="['/legales/true']" target="_blank">Acepto términos y condiciones</a></mat-checkbox>
        </div>
        <button mat-raised-button color="primary" [class]="btn_clase" type="submit" name="enviar"><img *ngIf="btn_loader" class="loader" src="../../assets/img/loader.gif">{{btn_text}}</button>
    </form>
    <article id="sucursales">
        <section>
            <p>Tucumán 725, General Roca.</p>
            <a href="https://goo.gl/maps/thgJPF54uT4mzCcD6" target="_blank"><img src="./../assets/img/redes/red_map.png"></a>
            <a href="https://wa.me/5492984141261" class="wp" target="_blank"><img src="../../assets/img/redes/red_1.png"></a>
        </section>
    </article>
    <article id="enlaces">
        <a href="https://www.instagram.com/creditosdelsuroficial" target="_blank"><img src="../../assets/img/redes/red_2.png"></a>
        <a href="https://www.facebook.com/creditosdelsuroficial" target="_blank"><img src="../../assets/img/redes/red_3.png"></a>
        <span>
			<a [routerLink]="['/legales']">legales</a>
		</span>
    </article>
</section>

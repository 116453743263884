import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { API_URL } from '../config';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  apiUrl = API_URL;

  constructor(
    private http: HttpClient
  ) { }


  EnvForm (datos) {
    return this.http.put(`${this.apiUrl}/servicios/sendmail/contactoweb`, datos);
  }

  EnvFormSimu (datos) {
    return this.http.put(`${this.apiUrl}/servicios/sendmail/contactowebsimu`, datos);
  }

  Simular(importe, cuotas) {

    let tasa: number = 253.5;
    let idamortizacion: number = 1;

    //http://25.95.31.152:1819/api/sucursal/simular/10000/6/100/1
    return this.http.get(`${this.apiUrl}/simular/${importe}/${cuotas}/${tasa}/${idamortizacion}`, {responseType: 'text'});

  }
}

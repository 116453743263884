import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LegalesComponent } from './legales/legales.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NavbarComponent } from './navbar/navbar.component';
import { SolicitudComponent } from './solicitud/solicitud.component';
import { SliderComponent } from './slider/slider.component';
import { ProductosComponent } from './productos/productos.component';
import { ContactoComponent } from './contacto/contacto.component';
import { FooterComponent } from './footer/footer.component';
import { ClientesComponent } from './clientes/clientes.component';
import { PrincipalComponent } from './principal/principal.component';

import { FormsPrincipalComponent } from './forms/principal.component';
import { SimularComponent } from './forms/simular/simular.component';
import { SolicitarComponent } from './forms/solicitar/solicitar.component'
import { ThankyouComponent } from './forms/thankyou/thankyou.component';

import { MascaraSoloNumeroInputDirective } from './directives/mascara-solo-numero-input.directive';
import { MascaraNumeroInputDirective } from './directives/mascara-numero-input.directive';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SolicitudComponent,
    SliderComponent,
    ProductosComponent,
    ContactoComponent,
    FooterComponent,
    ClientesComponent,
    LegalesComponent,
    PrincipalComponent,
    FormsPrincipalComponent,
    SimularComponent,
    SolicitarComponent,
    ThankyouComponent,
    MascaraSoloNumeroInputDirective,
    MascaraNumeroInputDirective

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
